@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #1e90ff;
  --white: #ffffff;
}
html,
body {
  color: var(--primary, 'red');
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.classToScroll{
  scroll-margin-top: 100px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--nav-evident-border-color,#384250);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(31 41 55 / var(--tw-bg-opacity));
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}
#map { height: 212px; width: 212px; }
